@import '~antd/dist/antd.css';

/* scrollbar */

::-ms-clear,
::-ms-reveal {
    display: none;
}

::-webkit-scrollbar-track {
    background-color: transparent;
}

::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

::-webkit-scrollbar-thumb {
    border-radius: 3px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    background-color: #d8d6d6;
}